import { Box, Flex, Heading, Image, Spinner, Text } from "@chakra-ui/react";
import Content from "../layout/Content";
import Layout from "../layout/Layout";
import { useEffect, useState } from "react";
import client from "../utils/client";
import AdsMobile from "../components/AdsMobile";
import AdsDesktop from "../components/AdsDesktop";

const Institucional = () => {
  const [fellowmembers, setFellowmembers] = useState();
  const [correspondents, setCorrespondents] = useState();

  useEffect(() => {
    const request = async () => {
      const response = await client.get("/users/type/members");

      if (response.data) {
        const fm = response.data
          .filter((member) =>
            member.type.includes(process.env.REACT_APP_FELLOWMEMBER)
          )
          .sort((a, b) => {
            // Converter chairNumber para número, ou usar um valor padrão caso esteja vazio
            const chairNumberA = a.chairNumber
              ? parseInt(a.chairNumber, 10)
              : Infinity;
            const chairNumberB = b.chairNumber
              ? parseInt(b.chairNumber, 10)
              : Infinity;

            // Comparar os valores convertidos
            return chairNumberA - chairNumberB;
          });

        const cp = response.data.filter((member) =>
          member.type.includes(process.env.REACT_APP_CORRESPONDENT)
        );
        setFellowmembers(fm);
        setCorrespondents(cp);
      }
    };
    request();
  }, []);

  return (
    <Layout>
      <Content>
        <Flex flexDir={"column"} gap={[4]} alignItems={"center"}>
          <AdsMobile type="principal" />
          <Flex
            flexDir={"row"}
            display={["none", "none", "flex"]}
            w={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <AdsDesktop type={"principal"} w={"60%"} />
            <AdsDesktop type={"secundario"} w={"40%"} />
          </Flex>
          <Heading
            px={[2, 6]}
            pt={[2]}
            variant={"outline"}
            colorScheme="yellow"
            textDecor={"underline"}
            alignSelf={"flex-start"}
            textDecorationColor={"#01acec"}
          >
            Acadêmicos
          </Heading>

          {/* Map para Acadêmicos */}
          <Flex wrap="wrap" justifyContent="center" gap={4}>
            {!!fellowmembers ? (
              fellowmembers.map((membro) => (
                <Flex
                  key={membro.name + membro.surname}
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  w={["90%", "45%", "30%"]}
                  alignItems="center"
                >
                  <Image
                    src={
                      !membro.patron
                        ? "/assets/defUser.webp"
                        : `https://amni-prod.s3.amazonaws.com/user_images/${membro.photo}`
                    }
                    alt="Imagem Placeholder"
                    boxSize="90px"
                    boxShadow={"md"}
                    border={"2px solid #cccccc"}
                    borderRadius={"100%"}
                    objectFit="cover"
                    mr={4}
                    aspectRatio={1}
                  />
                  <Box>
                    <Text fontWeight="bold">Cadeira {membro.chairNumber}</Text>
                    <Text>{membro.name + " " + membro.surname}</Text>
                    <Text fontStyle="italic" color="gray.600">
                      Patrono: {membro.patron}
                    </Text>
                  </Box>
                </Flex>
              ))
            ) : (
              <Spinner size="xl" />
            )}
          </Flex>
          <AdsMobile type="secundario" />
          <Heading
            px={[2, 6]}
            pt={[6]}
            variant={"outline"}
            colorScheme="yellow"
            textDecor={"underline"}
            alignSelf={"flex-start"}
            textDecorationColor={"#01acec"}
          >
            Membros Correspondentes
          </Heading>

          {/* Map para Correspondentes */}
          <Flex wrap="wrap" justifyContent="center" gap={4}>
            {!!correspondents ? (
              correspondents.map((membro) => (
                <Flex
                  key={membro.name + membro.surname}
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  w={["90%", "45%", "30%"]}
                  alignItems="center"
                >
                  <Image
                    src="/assets/defUser.webp"
                    alt="Imagem Placeholder"
                    boxSize="60px"
                    borderRadius={"100%"}
                    objectFit="cover"
                    mr={4}
                  />
                  <Box>
                    <Text fontWeight="bold">
                      {membro.name + " " + membro.surname}
                    </Text>
                  </Box>
                </Flex>
              ))
            ) : (
              <Spinner size="xl" />
            )}
          </Flex>
        </Flex>
      </Content>
    </Layout>
  );
};
export default Institucional;
