import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FaFlag } from "react-icons/fa6";
import { TbLogs } from "react-icons/tb";
import { IoLibrary } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import { ChevronDownIcon } from "@chakra-ui/icons";

const Header = () => {
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <>
      {/* Mobile */}
      <Link to={"/"}>
        <Flex
          display={["flex", "flex", "none"]}
          p={[2]}
          gap={[5]}
          alignItems={"center"}
          bgColor={"#ffffff"}
          m={2}
          maxW={"100%"}
          borderRadius={8}
        >
          <Image maxW={["4rem"]} src="/logoGrande.png" />
          <Flex flexDir={"column"}>
            <Text
              fontSize={["1.1rem"]}
              fontWeight={800}
              fontFamily={"Josefin Slab"}
            >
              ANMI
            </Text>
            <Text
              fontSize={["1.0rem"]}
              fontWeight={600}
              fontFamily={"Josefin Slab"}
            >
              Academia Nacional de Maçons Imortais
            </Text>
          </Flex>
        </Flex>
      </Link>

      {/* Desktop */}
      <Flex display={["none", "none", "flex"]} flexDir={"column"}>
        <Link to={"/"}>
          <Flex justifyContent={"center"} gap={5} alignItems={"center"} p={4}>
            <Image maxW={["8rem"]} src="/logoGrande.png" />
            <Flex flexDir={"column"}>
              <Text
                fontSize={["3rem"]}
                fontWeight={900}
                fontFamily={"Josefin Slab"}
              >
                ANMI
              </Text>
              <Text
                fontSize={["2rem"]}
                fontWeight={600}
                fontFamily={"Josefin Slab"}
              >
                Academia Nacional de Maçons Imortais
              </Text>
            </Flex>
          </Flex>
        </Link>
        <Box position="relative" p={5}>
          <Flex
            w={"100%"}
            justifyContent={"center"}
            gap={20}
            alignItems={"center"}
            flexGrow={0}
            flexShrink={0}
            flexBasis="auto"
          >
            {location.pathname !== "/academicos" ? (
              <Link to={"/academicos"}>
                <Flex alignItems={"center"} p={2} gap={1}>
                  <FaFlag color="#385898" />
                  <Button colorScheme="facebook" variant={"link"}>
                    Acadêmicos
                  </Button>
                </Flex>
              </Link>
            ) : (
              <Link to={"/"}>
                <Flex alignItems={"center"} p={2} gap={1}>
                  <FaHome color="#385898" />
                  <Button colorScheme="facebook" variant={"link"}>
                    Home
                  </Button>
                </Flex>
              </Link>
            )}

            {location.pathname !== "/blog" ? (
              <Link to={"/blog"}>
                <Flex alignItems={"center"} p={2} gap={1}>
                  <TbLogs color="#385898" />
                  <Button colorScheme="facebook" variant={"link"}>
                    Blog
                  </Button>
                </Flex>
              </Link>
            ) : (
              <Link to={"/"}>
                <Flex alignItems={"center"} p={2} gap={1}>
                  <FaHome color="#385898" />
                  <Button colorScheme="facebook" variant={"link"}>
                    Home
                  </Button>
                </Flex>
              </Link>
            )}

            {location.pathname !== "/biblioteca" ? (
              <Link to={"/biblioteca"}>
                <Flex alignItems={"center"} p={2} gap={1}>
                  <IoLibrary color="#385898" />
                  <Button colorScheme="facebook" variant={"link"}>
                    Biblioteca
                  </Button>
                </Flex>
              </Link>
            ) : (
              <Link to={"/"}>
                <Flex alignItems={"center"} p={2} gap={1}>
                  <FaHome color="#385898" />
                  <Button colorScheme="facebook" variant={"link"}>
                    Home
                  </Button>
                </Flex>
              </Link>
            )}

            {location.pathname !== "/login" ? (
              auth?.user && !!auth.user.id ? (
                <Flex>
                  <Flex gap={2}>
                    <Link to={"/perfil"}>
                      <Image
                        src={
                          `https://amni-prod.s3.amazonaws.com/user_images/${auth.user.photo}` ||
                          "/assets/defUser.webp"
                        }
                        w={"5rem"}
                        h={"5rem"}
                        borderRadius={"100%"}
                        objectFit={"cover"}
                        aspectRatio={1}
                      />
                    </Link>
                    <Flex flexDir={"column"} justifyContent={"center"} gap={1}>
                      <Link to={"/perfil"}>
                        <Text fontWeight={700} color="#385898">
                          Ir {auth.user.name}
                        </Text>
                      </Link>
                      <Button
                        size={"xs"}
                        colorScheme="red"
                        onClick={() => {
                          auth.logout();
                          navigate("/");
                        }}
                      >
                        Sair
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <Link to={"/login"}>
                  <Button colorScheme="green" size={"sm"}>
                    <Flex alignItems={"center"} gap={1}>
                      <FaUser />
                      Login
                    </Flex>
                  </Button>
                </Link>
              )
            ) : (
              <Link to={"/"}>
                <Flex alignItems={"center"} p={2} gap={1}>
                  <FaHome color="#385898" />
                  <Button colorScheme="facebook" variant={"link"}>
                    Home
                  </Button>
                </Flex>
              </Link>
            )}
            {auth.user?.type.includes(process.env.REACT_APP_ADMINISTRATOR) && (
              <Menu>
                <MenuButton
                  as={Button}
                  colorScheme="green"
                  rightIcon={<ChevronDownIcon />}
                >
                  Administrador
                </MenuButton>
                <MenuList zIndex="2000">
                  <Link to={"/gerenciamento_noticias"}>
                    <MenuItem>Gerenciar Notícias</MenuItem>
                  </Link>
                  <Link to={"/gerenciamento_usuarios"}>
                    <MenuItem>Gerenciar Usuários</MenuItem>
                  </Link>
                  <Link to={"/gerenciamento_postagens"}>
                    <MenuItem>Gerenciar Postagens</MenuItem>
                  </Link>
                  <Link to={"/gerenciamento_anunciantes"}>
                    <MenuItem>Gerenciar Anunciantes</MenuItem>
                  </Link>
                  <Link to={"/gerenciamento_biblioteca"}>
                    <MenuItem>Gerenciar Biblioteca</MenuItem>
                  </Link>
                </MenuList>
              </Menu>
            )}
            {(auth.user?.type.includes(process.env.REACT_APP_FELLOWMEMBER) ||
              auth.user?.type.includes(process.env.REACT_APP_CORRESPONDENT)) &&
              !auth.user?.type.includes(
                process.env.REACT_APP_ADMINISTRATOR
              ) && (
                <Menu>
                  <MenuButton
                    as={Button}
                    colorScheme="green"
                    rightIcon={<ChevronDownIcon />}
                  >
                    Produção
                  </MenuButton>
                  <MenuList zIndex="2000">
                    <Link to={"/gerenciamento_postagens"}>
                      <MenuItem>Gerenciar Postagens</MenuItem>
                    </Link>
                    {auth.user?.type.includes(
                      process.env.REACT_APP_LIBRARY
                    ) && (
                      <Link to={"/gerenciamento_biblioteca"}>
                        <MenuItem>Gerenciar Biblioteca</MenuItem>
                      </Link>
                    )}
                  </MenuList>
                </Menu>
              )}
            {auth.user?.type.includes(process.env.REACT_APP_LIBRARY) &&
              !auth.user?.type.includes(
                process.env.REACT_APP_ADMINISTRATOR
              ) && (
                <Menu>
                  <MenuButton
                    as={Button}
                    colorScheme="green"
                    rightIcon={<ChevronDownIcon />}
                  >
                    Bibliotecário
                  </MenuButton>
                  <MenuList zIndex="2000">
                    <Link to={"/gerenciamento_biblioteca"}>
                      <MenuItem>Gerenciar Biblioteca</MenuItem>
                    </Link>
                  </MenuList>
                </Menu>
              )}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
export default Header;
