import { Flex, IconButton, Image, Text } from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import { FaFlag } from "react-icons/fa6";
import { TbLogs } from "react-icons/tb";
import { IoLibrary } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { GiExitDoor } from "react-icons/gi";

const Footer = () => {
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <Flex
      display={["flex", "flex", "none"]}
      w={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      p={[2]}
      position={"sticky"}
      bottom={0}
      bgColor={"#333"}
      color={"white"}
      zIndex={"90"}
    >
      <Flex w={"100%"} py={1} px={3} gap={5} justifyContent={"space-between"}>
        {location.pathname !== "/login" ? (
          auth?.user && !!auth.user.id ? (
            <Flex
              flexDir={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={3}
            >
              <Link to={"/perfil"}>
                <Image
                  src={
                    `https://amni-prod.s3.amazonaws.com/user_images/${auth.user.photo}` ||
                    "/assets/defUser.webp"
                  }
                  w={"2rem"}
                  h={"2rem"}
                  objectFit={"cover"}
                  borderRadius={"100%"}
                />
              </Link>
              <IconButton
                colorScheme="red"
                size={"xs"}
                icon={<GiExitDoor />}
                fontSize="1.1rem"
                isRound={true}
                onClick={() => {
                  auth.logout();
                  navigate("/");
                }}
              />
            </Flex>
          ) : (
            <Link to={"/login"} style={{ flex: 1 }}>
              <Flex
                flexDir={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <FaUser />
                <Text fontSize={"0.75rem"}>Login</Text>
              </Flex>
            </Link>
          )
        ) : (
          <Link to={"/"} style={{ flex: 1 }}>
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <FaHome />
              <Text fontSize={"0.75rem"}>Home</Text>
            </Flex>
          </Link>
        )}

        {location.pathname !== "/academicos" ? (
          <Link to={"/academicos"} style={{ flex: 1 }}>
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <FaFlag />
              <Text fontSize={"0.75rem"}>Acadêmicos</Text>
            </Flex>
          </Link>
        ) : (
          <Link to={"/"} style={{ flex: 1 }}>
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <FaHome />
              <Text fontSize={"0.75rem"}>Home</Text>
            </Flex>
          </Link>
        )}

        {location.pathname !== "/blog" ? (
          <Link to={"/blog"} style={{ flex: 1 }}>
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <TbLogs />
              <Text fontSize={"0.75rem"}>Blog</Text>
            </Flex>
          </Link>
        ) : (
          <Link to={"/"} style={{ flex: 1 }}>
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <FaHome />
              <Text fontSize={"0.75rem"}>Home</Text>
            </Flex>
          </Link>
        )}

        {location.pathname !== "/biblioteca" ? (
          <Link to={"/biblioteca"} style={{ flex: 1 }}>
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <IoLibrary />
              <Text fontSize={"0.75rem"}>Biblioteca</Text>
            </Flex>
          </Link>
        ) : (
          <Link to={"/"} style={{ flex: 1 }}>
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <FaHome />
              <Text fontSize={"0.75rem"}>Home</Text>
            </Flex>
          </Link>
        )}
      </Flex>
    </Flex>
  );
};
export default Footer;
